
<div class="bg-gradient-to-r from-blue-300 to-yellow-200">
    <div class="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
        <div class="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
            <div class="border-t border-gray-200 text-center pt-8">
                <h1 class="text-9xl font-bold text-blue-400">404</h1>
                <h1 class="text-3xl font-medium py-8">الصفحة غير موجودة راجع الرابط من فظلك</h1>
                <p class="text-2xl pb-8 px-12 font-medium">Oops! La page est introuvable veillez verifier l'url de la
                    page merci!!.</p>
                <a
                routerLink = "/"
                    class="bg-gradient-to-r from-purple-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold 
                    px-6 py-3 rounded-md mr-6">
                    Retour
                </a>
                
            </div>
        </div>
    </div>
</div>
