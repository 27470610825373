export class User {
    public id!: number;
    public email!: string;
    public username!: string;
    public fonction!: string;
    public agence!: string;
    public isActive!: boolean;
    public typeUser!: string;
}

