import { Route } from '@angular/router';
import { NotfoundComponent } from './modules/notfound/notfound.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthComponent } from './modules/auth/auth.component';
import { AuthGuard } from './modules/auth/guards/auth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Landing routes

    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: AuthComponent },
   // { path: '', component: TombolaInscriptionComponent },
    // { path: 'offre', component: OffreComponent },
    {
      path: 'admin',
      component: LayoutComponent,
      loadChildren: () =>
        import('./modules/admin/admin.module').then((m) => m.AdminModule),
      canActivate: [AuthGuard],
    },
   

    {
      path: 'service-client',
      component: LayoutComponent,
      loadChildren: () =>
        import('./modules/service-client/service-client.module').then(
          (m) => m.ServiceClientModule
        ), canActivate: [AuthGuard],
    },
    {
      path: 'commercial',
      component: LayoutComponent,
      loadChildren: () =>
        import('./modules/commercial/commercial.module').then(
          (m) => m.CommercialModule
        ), canActivate: [AuthGuard],
    },
  

  
  


    { path: '404', component: NotfoundComponent },
    { path: '**', redirectTo: '/404' }


];
