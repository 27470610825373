import { FuseNavigationItem } from "src/@fuse/components/navigation/navigation.types";


export const MENUSEVICECLIENTADMIN :FuseNavigationItem[] =
    [
        {
            id: 'Menu - Service client ',
            title:'Menu - Service client',
            type: 'basic',
        },
        {
            id: 'dashboards',
            title: 'Dashboards',
            subtitle: 'Dashboard - Service client',
            type: 'basic',
            icon: 'heroicons_outline:menu',
            link: '/service-client'
        },
      
        {
            id: 'Requetes',
            title: 'Requetes',
            type: 'basic',
            icon: 'heroicons_outline:inbox-in',
            link: '/service-client/demandes'
        },

        {
            id: 'Statistiques',
            title: 'Statistiques',
            type: 'collapsable',
            icon: 'heroicons_outline:chart-pie',
            children: [
                {
                    id: 'Mes statistiques',
                    title: 'Mes statistiques',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-bar',
                    link: '/service-client/statistiques/mes-statistiques'
                },
                {
                    id: 'Statistiques stations',
                    title: 'Statistiques stations',
                    type: 'basic',
                    icon: 'heroicons_outline:office-building',
                    link: '/service-client/statistiques/list-stations'
                },
                {
                    id: 'Statistiques CS',
                    title: 'Statistiques CS',
                    type: 'basic',
                    icon: 'heroicons_outline:user-group',
                    link: '/service-client/statistiques/list-cs'
                },
            ]
            }
    
        
    ];
