<!-- Button -->
<button
title="Mes informations"

    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
   
        <mat-icon
            *ngIf="!showAvatar || !user?.image"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>

    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <div mat-menu-item>
        <span class="flex flex-col leading-none">

            <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
        </span>
    </div>
    <div mat-menu-item>
        <span class="flex flex-col leading-none">

            <span class="mt-1.5 text-md font-medium text-dark">{{user?.fonction}}</span>
        </span>
    </div>
    <div mat-menu-item>
        <span class="flex flex-col leading-none">

            <span class="mt-1.5 text-md font-medium">{{user?.agence}}</span>
        </span>
    </div>
    <!-- <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button> -->

    <!-- <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()"> -->
        <!-- <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon> -->
        <!-- <span>Déconnecter</span>
    </button> -->
</mat-menu>


