import { FuseNavigationItem } from "src/@fuse/components/navigation/navigation.types";


export const MENUCOMMERCIAL: FuseNavigationItem[] =
    [
        {
            id: 'Menu - Commercial ',
            title: 'Menu - Commercial',
            type: 'basic',
        },
        {
            id: 'dashboards',
            title: 'Dashboards',
            subtitle: 'Dashboard - Commercial',
            type: 'basic',
            icon: 'heroicons_outline:menu',
            link: '/commercial'
        },


        {
            id: 'Requetes',
            title: 'Requetes',
            type: 'basic', 
            icon: 'heroicons_outline:inbox-in',
            link: '/commercial/demandes'
        },

        
        // {
        //     id: 'Inscriptions',
        //     title: 'Inscriptions',
        //     type: 'basic',
        //     icon: 'heroicons_outline:inbox-in',
        //     link: '/commercial/tombola/inscriptions'
        // },
        // {
        //     id: 'statistiques',
        //     title: 'statistiques',
        //     type: 'basic',
        //     icon: 'heroicons_outline:chart-bar',
        //     link: '/commercial/statistiques/statistiques'
        // },




    ];
