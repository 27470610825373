import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SettingsModule } from './common/settings/settings.module';
import { LayoutComponent } from './layout.component';
import { ClassyLayoutModule } from './layouts/vertical/classy/classy.module';
const layoutModules = [

    ClassyLayoutModule,

];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports     : [
        SharedModule,
        SettingsModule,
        ...layoutModules
    ],
    exports     : [
        LayoutComponent,
        ...layoutModules
    ]
})
export class LayoutModule
{
}
