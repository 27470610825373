import { FuseNavigationItem } from "src/@fuse/components/navigation/navigation.types";


export const MENUSEVICECLIENTCS: FuseNavigationItem[] =
    [
        {
            id: 'Menu - Service client ',
            title: 'Menu - Service client',
            type: 'basic',
        },
        {
            id: 'dashboards',
            title: 'Dashboards',
            subtitle: 'Dashboard - Service client',
            type: 'basic',
            icon: 'heroicons_outline:menu',
            link: '/service-client'
        },

        {
            id: 'Requetes',
            title: 'Requetes',
            type: 'basic',
            icon: 'heroicons_outline:inbox-in',
            link: '/service-client/demandes'
        },


        {
            id: 'Mes statistiques',
            title: 'Mes statistiques',
            type: 'basic',
            icon: 'heroicons_outline:chart-bar',
            link: '/service-client/statistiques/mes-statistiques'
        },




    ];
