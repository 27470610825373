import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseNavigationService } from 'src/@fuse/components/navigation/navigation.service';
import { FuseVerticalNavigationComponent } from 'src/@fuse/components/navigation/vertical/vertical.component';
import { FuseMediaWatcherService } from 'src/@fuse/services/media-watcher/media-watcher.service';

import { MENUSUPERADMIN } from 'src/app/menus/superAdmin.menu';

import { AuthService } from 'src/app/modules/auth/auth.service';
import { IUser } from 'src/app/core/interfaces/i-user';
import { MENUSEVICECLIENTADMIN } from 'src/app/menus/admin.menu';
import { MENUSEVICECLIENTCS } from 'src/app/menus/agentcs.menu';
import { MENUCOMMERCIAL } from 'src/app/menus/commercial.menu';




@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation=[null];
    user: IUser  = {};
    isLogged: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private authService:AuthService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
    }

    roleMenu = {
        '236359452023':MENUSEVICECLIENTCS,
        '2363594520':MENUSEVICECLIENTADMIN,
        '963734':MENUSUPERADMIN,
        '546786549':MENUCOMMERCIAL,

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */


    ngOnInit(): void {
          if (this.authService.currentUser) {
            this.user.email=this.authService.currentUser['email']
            this.user.username=this.authService.currentUser['username']
            this.user.typeUser=this.authService.currentUser['typeUser']
            this.user.agence=this.authService.currentUser['agence']
            this.user.fonction=this.authService.currentUser['fonction']
        }
        if (this.user?.fonction =='Téléoperateur' || this.user?.fonction =='Agent service client' ) {
            this.navigation = this.roleMenu[236359452023]
            
        }else{
            this.navigation = this.roleMenu[this.user?.typeUser]

        }


        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    removeDuplicates(myArray, Prop) {
        return myArray.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
        });
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
    goHome() {
        // Chemin de la route vers laquelle vous voulez naviguer
        this.authService.logout()
      
       // this.router.navigateByUrl(`${url}?${queryParams.ident}`);
     }
 
}
