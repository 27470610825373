import { FuseNavigationItem } from "src/@fuse/components/navigation/navigation.types";


export const MENUSUPERADMIN: FuseNavigationItem[] =
    [
        {
            id: 'Menu - Admin ',
            title: 'Menu - Admin',
            type: 'basic',
        },
        {
            id: 'dashboards',
            title: 'Dashboards',
            subtitle: 'Dashboard - Admin',
            type: 'basic',
            icon: 'heroicons_outline:menu',
            link: '/admin'
        },
        {
            id: 'Employés',
            title: 'Employés',
            type: 'collapsable',
            icon: 'heroicons_outline:user-group',
            children: [
                {
                    id: 'Ajouter un employé',
                    title: 'Ajouter un employé',
                    type: 'basic',
                    icon: 'heroicons_outline:user-add',
                    link: '/admin/employés/ajouter-employé'
                },
                {
                    id: 'Liste des employés',
                    title: 'Liste des employés',
                    type: 'basic',
                    icon: 'heroicons_outline:user-group',
                    link: '/admin/employés/liste-employés'
                },

                {
                    id: 'Supervisions',
                    title: 'Supervisions',
                    type: 'basic',
                    icon: 'heroicons_outline:menu',
                    link: '/admin/employés/supervsions'
                },

            ]
        },

        {
            id: 'Liste de agences',
            title: 'Liste de agences',
            type: 'basic',
            icon: 'heroicons_outline:menu',
            link: '/admin/agences/liste-agences'
        },


    ];
